import React from 'react';
import { useForm, ValidationError } from '@formspree/react';
import classnames from 'tailwindcss-classnames';
import { ServiceArea } from './ServiceArea';

export const Contact = () => {
    const [state, handleSubmit] = useForm("mleyjapj");

    if (state.succeeded) {
        return <p>Thank you! We will be in contact as soon as possible!</p>;
    }

    return (
        <div className={classnames(
            'flex',
            'flex-col',
            'xl:flex-row',
            'min-h-max',
            'w-full',
            'bg-light-blue',
            'justify-center',
            'xl:py-8'
        )}>
            <form
                className={classnames(
                    'flex',
                    'flex-col',
                    'h-auto',
                    'w-auto',
                    'bg-white',
                    'm-8',
                    'sm:m-12',
                    'xl:ml-16',
                    'xl:mr-8',
                    'p-8',
                    'sm:p-12',
                    'xl:px-24',
                    'items-center',
                    'rounded-lg',
                    'drop-shadow-md'
                )}
                onSubmit={handleSubmit}
            >
                <h1 className={classnames(
                    'pb-8',
                    'sm:pb-16',
                    'font-dosis',
                    'font-bold',
                    'text-4xl',
                    'text-green-blue',
                    'drop-shadow-sm'
                )}
                >
                    Get In Touch
                </h1>
                <div
                    className={classnames(
                        'flex',
                        'flex-col',
                        'pb-8',
                        'w-full',
                        'font-dm',
                        'font-medium',
                        'text-xl'
                    )}
                >
                    <input
                        id="name"
                        name="name"
                        className={classnames(
                            'border-b-2',
                            'border-gray-400',
                            'focus:outline-none',

                        )}
                        placeholder="Name"
                        required={true}
                    />
                    <ValidationError
                        prefix="Message"
                        field="message"
                        errors={state.errors}
                    />
                </div>
                <div
                    className={classnames(
                        'flex',
                        'flex-col',
                        'pb-8',
                        'w-full',
                        'font-dm',
                        'font-medium',
                        'text-xl'
                    )}>
                    <input
                        type="telephone"
                        name="telephone"
                        id="telephone"
                        placeholder="Phone Number"
                        className={classnames(
                            'border-b-2',
                            'border-gray-400',
                            'focus:outline-none'
                        )}
                        required={true}
                    />
                </div>
                <div
                    className={classnames(
                        'flex',
                        'flex-col',
                        'pb-8',
                        'w-full',
                        'font-dm',
                        'font-medium',
                        'text-xl'
                    )}
                >
                    <input
                        id="email"
                        type="email"
                        name="email"
                        className={classnames(
                            'border-b-2',
                            'border-gray-400',
                            'focus:outline-none'
                        )}
                        placeholder="Email Address"
                        required={true}
                    />
                    <ValidationError
                        prefix="Email"
                        field="email"
                        errors={state.errors}
                    />
                </div>
                <div
                    className={classnames(
                        'flex',
                        'flex-col',
                        'pb-8',
                        'w-full',
                        'font-dm',
                        'font-medium',
                        'text-xl'
                    )}
                >
                    <input
                        id="location"
                        name="location"
                        className={classnames(
                            'border-b-2',
                            'border-gray-400',
                            'focus:outline-none'
                        )}
                        placeholder="Location"
                        required={true}
                    />
                    <ValidationError
                        prefix="Message"
                        field="message"
                        errors={state.errors}
                    />
                </div>
                <div
                    className={classnames(
                        'flex',
                        'flex-col',
                        'pb-8',
                        'w-full',
                        'font-dm',
                        'font-medium',
                        'text-xl',
                    )}
                >
                    <textarea
                        id="message"
                        name="message"
                        className={classnames(
                            'border-b-2',
                            'border-gray-400',
                            'focus:outline-none'

                        )}
                        placeholder="Extra Info (Optional)"
                        required={true}
                    />
                    <ValidationError
                        prefix="Message"
                        field="message"
                        errors={state.errors}
                    />
                </div>
                <button
                    type="submit"
                    disabled={state.submitting}
                    className={classnames(
                        'px-6',
                        'py-4',
                        'bg-rexine-blue',
                        'hover:bg-blue-500',
                        'rounded-lg',
                        'text-white',
                        'text-3xl',
                        'tracking-wider',
                        'font-signika', 
                        'font-light', 
                        'mt-6',
                        'drop-shadow-md'
                    )}
                >
                    Send
                </button>
            </form>
            <div
                className={classnames(
                    'rexine-spa-care-service-page',
                    'relative',
                    'flex',
                    'flex-col',
                    'place-items-center',
                    'xl:my-12',
                    'font-bold',
                    'font-dosis',
                    'text-4xl',
                    'text-green-blue',
                    'bg-white',
                    'rounded-lg',
                    'p-8',
                    'mx-8',
                    'sm:mx-12',
                    'xl:ml-6',
                    'xl:mr-12',
                    'mb-8',
                    'sm:mb-12',
                    'drop-shadow-md'
                )}
            >
                <h1 className={classnames('pt-2', 'sm:pt-4', 'pb-5', 'drop-shadow-sm')}>Service Area</h1>
                <ServiceArea />
            </div>
        </div>
    );
}